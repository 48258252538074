import axios from 'axios'

// Create instance called instance
const http = axios.create({
    baseURL: 'https://lm-be.rev9solutions.com/api/v1',
});

// var token = localStorage.getItem('token');

http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

// declare a response interceptor
http.interceptors.response.use((response) => {
    return response;
}, error => {
    // handle the response error
    
    if (error.response.status == 401) {
        window.location.href = '/#/login';
    } else {
        return Promise.reject(error);
    }
});


export default http;
